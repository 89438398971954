import {
  animate,
  MotionValue,
  useMotionValue,
} from "framer-motion";
import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  setConsent,
  setAnalyticsCollectionEnabled,
  getAnalytics,
} from "@firebase/analytics";
import { useLocation } from "react-router-dom";
import { useMobile } from "./useMobile";

export type IUseAppProvider = {
  scrollY: MotionValue<number>;
  scrollToStart: () => void;
  scrollMaxY: number;
  scrollToEnd: () => void;
  setCookiesOpen: Dispatch<SetStateAction<boolean>>;
  scrollToPosition: (position: number) => void;
  showKontaktModal: boolean;
  setShowKontaktModal: Dispatch<SetStateAction<boolean>>;
  allowAnalytics: () => void;
  declineAnalytics: () => void;
  toggleAnalytics: () => void;
  cookiesOpen: boolean;
  useAnalytics: boolean;
  hideBecauseInfoPage: boolean;
  setCurrentCookieSettings: () => void;
  currentStory: string;
  play: () => void;
  pause: () => void;
  playing: boolean;
};

const calculateAnimateDelta = (delta: number): number => {
  if (delta < 50) {
    return delta * 20;
  }
  if (delta < 100) {
    return delta * 10;
  }
  if (delta < 200) {
    return delta * 5;
  }
  return delta;
};

export type Stamp = {
  title: string;
  start: number;
  end: number;
};

export const stamps: Stamp[] = [
  {
    title: "Campaign",
    start: 0,
    end: 14000,
  },
  {
    title: "Portraits",
    start: 14000,
    end: 20000,
  },
  {
    title: "Reportage",
    start: 20000,
    end: 42000,
  },
  {
    title: "Press",
    start: 42000,
    end: 48000,
  },
  {
    title: "Sport",
    start: 48000,
    end: 57000,
  },
  {
    title: "Classical",
    start: 57000,
    end: 61000,
  },
  {
    title: "Landscape",
    start: 61000,
    end: 80000,
  },
  {
    title: "Fin",
    start: 80000,
    end: 90000,
  },
];

const scrollMaxY = 90000;

const AppContext = createContext({} as IUseAppProvider);
const useApp = () => useContext(AppContext);
const useAppProvider = (): IUseAppProvider => {
  const mobile = useMobile();
  const { pathname } = useLocation();
  const scrollY = useMotionValue(0);
  const [useAnalytics, setUseAnalytics] = useState<boolean>(false);
  const [showKontaktModal, setShowKontaktModal] = useState<boolean>(false);
  const [currentStory, setCurrentStory] = useState<string>("");
  const [cookiesAccepted, setCookiesAccepted] = useState<undefined | boolean>(
    undefined
  );
  const [cookiesOpen, setCookiesOpen] = useState<boolean>(false);
  const hideBecauseInfoPage = useMemo(() => {
    if(pathname === "/impressum" || pathname === "/datenschutz") {
      return true;
    }
    return false;
  }, [pathname])
  const [playing, setPlaying] = useState<boolean>(false);

  const setStoryTitle = (newY: number) => {
    let found = false;

    for (let i = 0; i < stamps.length; i++) {
      if (newY >= stamps[i].start && newY < stamps[i].end) {
        found = true;
        setCurrentStory(stamps[i].title);
        break;
      }
    }

    if (!found) {
      setCurrentStory("");
    }
  };

  React.useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      if (!cookiesAccepted || cookiesOpen) {
        return;
      }
      if (mobile) {
        return;
      }

      const current = scrollY.get();
      const value = calculateAnimateDelta(e.deltaY);
      const newY = current + value;

      if (newY < 0) {
        animate(scrollY, 0, {
          type: "spring",
          damping: 30,
        });
        setStoryTitle(0);
        return;
      }

      if (newY > scrollMaxY) {
        animate(scrollY, scrollMaxY, {
          type: "spring",
          damping: 30,
        });
        setStoryTitle(scrollMaxY);
        return;
      }

      animate(scrollY, newY, {
        type: "spring",
        damping: 30,
      });
      setStoryTitle(newY);
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      if (mobile) {
        return;
      }
      if (e.key === "ArrowUp") {
        const current = scrollY.get();
        const newY = current - 500;

        if (newY < 0) {
          animate(scrollY, 0, {
            type: "spring",
            damping: 30,
          });
          setStoryTitle(0);
          return;
        }

        animate(scrollY, newY, {
          type: "spring",
          damping: 30,
        });
        setStoryTitle(newY);
      }

      if (e.key === "ArrowDown") {
        const current = scrollY.get();
        const newY = current + 500;

        if (newY > scrollMaxY) {
          animate(scrollY, scrollMaxY, {
            type: "spring",
            damping: 30,
          });
          setStoryTitle(scrollMaxY);
          return;
        }

        animate(scrollY, newY, {
          type: "spring",
          damping: 30,
        });
        setStoryTitle(newY);
      }
    };

    window.addEventListener("wheel", handleWheel);
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("wheel", handleWheel);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [cookiesAccepted, cookiesOpen, mobile, scrollY]);

  const scrollToStart = React.useCallback(() => {
    if (scrollY.get() > 0) {
      animate(scrollY, 0, {
        type: "spring",
        damping: 30,
        duration: 10,
      });
    }
  }, [scrollY]);

  const scrollToEnd = React.useCallback(() => {
    if (scrollY.get() < scrollMaxY) {
      animate(scrollY, scrollMaxY, {
        type: "spring",
        damping: 30,
        duration: 10,
      });
    }
  }, [scrollY]);

  const scrollToPosition = React.useCallback(
    (position: number) => {
      const current = scrollY.get();
      const duration = Math.abs(current - position) / 1000;

      animate(scrollY, position, {
        type: "spring",
        damping: 30,
        duration,
      });
      setStoryTitle(position);
    },
    [scrollY]
  );

  const allowAnalytics = useCallback(() => {
    setUseAnalytics(true);
    setConsent({
      analytics_storage: "granted",
      ad_storage: "denied",
      functionality_storage: "granted",
      security_storage: "denied",
      personalization_storage: "denied",
    });
    localStorage.setItem("google-analytics-consent", "true");
    const analytics = getAnalytics()
    if (analytics) {
      setAnalyticsCollectionEnabled(analytics, true);
    }
    setCookiesAccepted(true);
  }, []);

  const declineAnalytics = useCallback(() => {
    setUseAnalytics(false);
    setConsent({
      analytics_storage: "granted",
      ad_storage: "denied",
      functionality_storage: "denied",
      security_storage: "denied",
      personalization_storage: "denied",
    });
    localStorage.setItem("google-analytics-consent", "false");
    const analytics = getAnalytics()
    if (analytics) {
      setAnalyticsCollectionEnabled(analytics, false);
    }

    setCookiesAccepted(true);
  }, []);

  const toggleAnalytics = useCallback(() => {
    setUseAnalytics((prev) => !prev);
    setConsent({
      analytics_storage: "granted",
      ad_storage: "denied",
      functionality_storage: useAnalytics ? "granted" : "denied",
      security_storage: "denied",
      personalization_storage: "denied",
    });
    localStorage.setItem(
      "google-analytics-consent",
      useAnalytics ? "true" : "false"
    );
    const analytics = getAnalytics()
    if (analytics) {
      setAnalyticsCollectionEnabled(analytics, useAnalytics);
    }

    setCookiesAccepted(true);
  }, [useAnalytics]);

  const setCurrentCookieSettings = useCallback(() => {
    setConsent({
      analytics_storage: "granted",
      ad_storage: "denied",
      functionality_storage: useAnalytics ? "granted" : "denied",
      security_storage: "denied",
      personalization_storage: "denied",
    });
    localStorage.setItem(
      "google-analytics-consent",
      useAnalytics ? "true" : "false"
    );
    setCookiesAccepted(true);
    const analytics = getAnalytics()
    if (analytics) {
      setAnalyticsCollectionEnabled(analytics, useAnalytics);
    }
  }, [useAnalytics]);

  useEffect(() => {
    // check if google analytics is granted or denied
    const consent = localStorage.getItem("google-analytics-consent");
    if (consent === "true") {
      allowAnalytics();
      setCookiesAccepted(true);
    } else if (consent === "false") {
      declineAnalytics();
      setCookiesAccepted(true);
    } else {
      setCookiesOpen(true);
    }
  }, [allowAnalytics, declineAnalytics]);

  const play = useCallback(() => {
    setPlaying(true);
    animate(scrollY, scrollMaxY, {
      duration: 90,
      ease: "linear",
    });
  }, [scrollY]);

  const pause = useCallback(() => {
    setPlaying(false);
    const value = scrollY.get();
    animate(scrollY, value, {
      duration: 0.1,
      ease: "linear",
    });
  }, [scrollY]);

  return {
    scrollToPosition,
    play,
    scrollToStart,
    scrollY,
    scrollToEnd,
    scrollMaxY,
    showKontaktModal,
    setCurrentCookieSettings,
    setShowKontaktModal,
    pause,
    currentStory,
    hideBecauseInfoPage,
    cookiesOpen,
    allowAnalytics,
    playing,
    setCookiesOpen,
    toggleAnalytics,
    declineAnalytics,
    useAnalytics,
  };
};
const AppProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const value = useAppProvider();
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export { useApp, AppProvider };
