import styled from "@emotion/styled";
import { FC } from "react";
import { Triangle } from "react-loader-spinner";

const Wrapper = styled("div")({
  width: "100%",
  height: "100vh",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
});

const PageLoading: FC = () => {
  return (
    <Wrapper>
      <Triangle
        height="80"
        width="80"
        color="#333"
        ariaLabel="triangle-loading"
        wrapperStyle={{}}
        visible={true}
      />
    </Wrapper>
  );
};

export default PageLoading;
