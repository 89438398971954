import { FC } from "react";
import { motion } from "framer-motion";
import styled from "@emotion/styled";

const spring = {
  type: "spring",
  stiffness: 700,
  damping: 30,
};

const SwitchStyled = styled("div", {
  shouldForwardProp: (prop) => prop !== "on",
})<{ on: boolean }>(({ on }) => ({
  width: 70,
  height: 36,
  backgroundColor: on ? "rgb(52,173,43)" : "rgba(0, 0, 0, 0.4)",
  display: "flex",
  justifyContent: "flex-start",
  borderRadius: 50,
  padding: 4,
  cursor: "pointer",
  "&[data-ison='true']": {
    justifyContent: "flex-end",
  },
}));

const Handle = styled(motion.div)(() => ({
  width: 28,
  height: 28,
  backgroundColor: "#fff",
  borderRadius: "50%",
}));

type Props = {
  on: boolean;
  onChange: (on: boolean) => void;
};

const Switch: FC<Props> = ({ on, onChange }) => {
  const toggleSwitch = () => onChange(!on);

  return (
    <SwitchStyled on={on} data-ison={on} onClick={toggleSwitch}>
      <Handle className="handle" layout transition={spring} />
    </SwitchStyled>
  );
};

export default Switch;
