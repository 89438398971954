import styled from "@emotion/styled";
import { FC } from "react";
import { useMobile } from "../hooks/useMobile";
import Portal from "./Portal";

const Wrapper = styled("div")({
  position: "fixed",
  zIndex: 3,
  bottom: "2rem",
  right: "2rem",
  borderRadius: 12,
  padding: "0.3rem 0.5rem",
  backdropFilter: "blur(10px)",
  backgroundColor: "rgba(0,0,0,0.3)",
});

const Text = styled("p")(() => ({
  margin: 0,
  fontSize: 13,
  color: "#fff",
}));

const Link = styled("a")(() => ({ color: "inherit" }));

const DesignedBy: FC = () => {
  const mobile = useMobile();

  if (mobile) {
    return null;
  }

  return (
    <Portal wrapperId={"designedby"}>
      <Wrapper>
        <Text>
          Designed by{" "}
          <Link
            href="mailto:maximilian.jeschek@gmx.de"
            target="_blank"
            rel="noreferrer"
          >
            @maxjeschek
          </Link>
        </Text>
      </Wrapper>
    </Portal>
  );
};

export default DesignedBy;

