import styled from "@emotion/styled";
import { motion } from "framer-motion";
import React, { lazy, Suspense } from "react";
import Start from "../stories/Start";
import Hero from "../components/Hero";
import { useMobile } from "../hooks/useMobile";
import PageLoading from "../components/PageLoading";

const KontaktButton = lazy(() => import("../components/KontaktButton"));
const Campaign = lazy(() => import("../stories/Campaign"));
const Portrait = lazy(() => import("../stories/Portrait"));
const Reportage = lazy(() => import("../stories/Reportage"));
const Press = lazy(() => import("../stories/Press"));
const Sport = lazy(() => import("../stories/Sport"));
const Classical = lazy(() => import("../stories/Classical"));
const End = lazy(() => import("../stories/End"));
const Landscape = lazy(() => import("../stories/Landscape"));
const PlayButton = lazy(() => import("../components/PlayButton"));

const Main = styled(motion.main, {
  shouldForwardProp: (prop) => prop !== "mobile",
})<{ mobile: boolean }>(({ mobile }) => ({
  position: mobile ? "relative" : "fixed",
  top: mobile ? "unset" : 0,
  left: mobile ? "unset" : 0,
  right: mobile ? "unset" : 0,
  bottom: mobile ? "unset" : 0,
  width: "100%",
  height: mobile ? "100%" : "100vh",
  display: mobile ? "block" : "flex",
  flexDirection: mobile ? "unset" : "row",
  overflow: mobile ? "unset" : "hidden",
  flexWrap: mobile ? "unset" : "nowrap",
  minHeight: mobile ? "100vh" : "unset",
  paddingTop: mobile ? 60 : "unset",
}));

const Home: React.FC = () => {
  const mobile = useMobile();
  return (
    <Main mobile={mobile}>
      <Suspense fallback={<PageLoading />}>
        <Hero />
        <Start />
        <Campaign />
        <Portrait />
        <Reportage />
        <Press />
        <Sport />
        <Classical />
        <Landscape />
        <KontaktButton />
        <End />
        <PlayButton />
      </Suspense>
    </Main>
  );
};

export default Home;
