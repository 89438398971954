import { FC, useEffect, useState } from "react";
import {
  animate,
  AnimatePresence,
  motion,
  useMotionValue,
} from "framer-motion";
import styled from "@emotion/styled";
import { getAnalytics, logEvent } from "@firebase/analytics";
import { Link as NavLink } from "react-router-dom";
import Portal from "./Portal";
import Backdrop from "./Backdrop";
import Switch from "./Switch";
import { useApp } from "../hooks/useAppProvider";

const Link = styled(NavLink)(() => ({
  "@media (max-width: 768px)": {
    fontSize: "0.7rem",
  },
}));

const Wrapper = styled(motion.div)({
  position: "fixed",
  left: "50%",
  top: "50%",
  width: "clamp(300px, 80vw, 800px)",
  maxHeight: "95%",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  backgroundColor: "#fff",
  borderRadius: "1rem",
  boxShadow: "0 0 1rem rgba(0, 0, 0, 0.2)",
  zIndex: 2000,
  overflowY: "auto",
});

const Button = styled(motion.button, {
  shouldForwardProp: (prop) => prop !== "filled",
})<{ filled?: boolean }>(({ filled }) => ({
  backgroundColor: "transparent",
  border: "2px solid #222",
  color: "#222",
  padding: "0.5rem 1rem",
  fontSize: "1rem",
  "@media (max-width: 768px)": {
    padding: "0.3rem 1rem",
    fontSize: "0.9rem",
  },
  borderRadius: "0.5rem",
  cursor: "pointer",
  whiteSpace: "nowrap",
  ...(filled && {
    backgroundColor: "#222",
    color: "#fff",
    border: "2px solid #222",
  }),
  "&:last-of-type": {
    marginLeft: "auto",
  },
}));

const ButtonsWrapper = styled("div")({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: "1rem",
  "@media screen and (max-width: 768px)": {
    flexDirection: "column",
  },
  marginTop: "1rem",
});

const SettingsWrapper = styled(motion.div)({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#fff",
  borderRadius: "1rem",
  boxShadow: "0 0 1rem rgba(0, 0, 0, 0.2)",
  padding: "2rem",
  position: "absolute",
  top: "50%",
  left: "50%",
  "@media screen and (max-width: 768px)": {
    width: "90%",
  },
});

const Title = styled("h1")(() => ({
  fontSize: 23,
  margin: 0,
}));

const Text = styled("p")(() => ({
  margin: "1rem 0 0 0",
  "@media (max-width: 768px)": {
    fontSize: "0.8rem",
  },
}));

const CookieTitleWrapper = styled("div")({
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  justifyContent: "space-between",
  alignItems: "center",
});

const InfoFooter = styled("div")({
  display: "flex",
  width: "100%",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "1rem",
  borderBottomRightRadius: "1rem",
  borderBottomLeftRadius: "1rem",
  backgroundColor: "rgba(0,0,0,0.1)",
  gap: "1rem",
  "& a": {
    color: "#000",
    textDecoration: "none",
    display: "block",
  },
});

const Body = styled("div")(() => ({ padding: "1rem" }));

const Cookies: FC = () => {
  const {
    cookiesOpen,
    setCookiesOpen,
    useAnalytics,
    toggleAnalytics,
    hideBecauseInfoPage,
    allowAnalytics,
    declineAnalytics,
    setCurrentCookieSettings,
  } = useApp();
  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);
  const scale = useMotionValue(1);

  useEffect(() => {
    if (cookiesOpen && !hideBecauseInfoPage) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [cookiesOpen, hideBecauseInfoPage]);

  const handleBackdropClick = () => {
    if (cookiesOpen) {
      animate(scale, 1.1, {
        type: "spring",
        stiffness: 500,
        damping: 30,
        onComplete: () => {
          animate(scale, 1, {
            type: "spring",
            stiffness: 500,
            damping: 30,
          });
        },
      });
    }
  };

  return (
    <Portal wrapperId={"cookies"}>
      <AnimatePresence>
        {cookiesOpen && !hideBecauseInfoPage && (
          <Backdrop
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ scale }}
            onClick={handleBackdropClick}
          >
            <Wrapper
              initial={{ y: "-20%", x: "-50%", opacity: 0 }}
              animate={{
                y: "-50%",
                x: "-50%",
                opacity: 1,
              }}
              exit={{ y: "-20%", x: "-50%", opacity: 0 }}
              onClick={(e) => e.stopPropagation()}
            >
              <Body>
                <Title>Diese Website verwendet Cookies</Title>
                <Text>
                  Wir verwenden Cookies, um Inhalte und Anzeigen zu
                  personalisieren, Funktionen für soziale Medien anbieten zu
                  können und die Zugriffe auf unsere Website zu analysieren.
                  Außerdem geben wir Informationen zu Ihrer Verwendung unserer
                  Website an unsere Partner für soziale Medien, Werbung und
                  Analysen weiter. Unsere Partner führen diese Informationen
                  möglicherweise mit weiteren Daten zusammen, die Sie ihnen
                  bereitgestellt haben oder die sie im Rahmen Ihrer Nutzung der
                  Dienste gesammelt haben.
                </Text>
                <Text style={{ fontSize: 12, color: "rgba(0,0,0,0.5)" }}>
                  Einige Services verarbeiten personenbezogene Daten in den USA.
                  Indem du der Nutzung dieser Services zustimmst, erklärst du
                  dich auch mit der Verarbeitung deiner Daten in den USA gemäß
                  Art. 49 (1) lit. a DSGVO einverstanden. Die USA werden vom
                  EuGH als ein Land mit einem unzureichenden Datenschutzniveau
                  nach EU-Standards angesehen. Insbesondere besteht das Risiko,
                  dass deine Daten von US-Behörden zu Kontroll- und
                  Überwachungszwecken verarbeitet werden, unter Umständen ohne
                  die Möglichkeit eines Rechtsbehelfs.U
                </Text>
                <ButtonsWrapper>
                  <Button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    type={"button"}
                    title={"decline cookies"}
                    onClick={() => {
                      declineAnalytics();
                      setCookiesOpen(false);
                    }}
                  >
                    Weiter ohne Einwilligung
                  </Button>
                  <Button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    type={"button"}
                    title={"cookie settings"}
                    onClick={() => {
                      setSettingsOpen(true);
                      const analytics = getAnalytics();
                      if (analytics) {
                        logEvent(analytics, "cookie_settings_opened");
                      }
                    }}
                  >
                    Einstellungen
                  </Button>
                  <Button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    filled
                    type={"button"}
                    title={"accept all cookies"}
                    onClick={() => {
                      allowAnalytics();
                      setCookiesOpen(false);
                    }}
                  >
                    Alle akzeptieren
                  </Button>
                </ButtonsWrapper>
              </Body>
              <InfoFooter>
                <Link to={"/datenschutz"}>Datenschutzerklärung</Link>
                <Link to={"/impressum"}>Impressum</Link>
              </InfoFooter>
            </Wrapper>
          </Backdrop>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {settingsOpen && cookiesOpen && (
          <Backdrop
            style={{ zIndex: 1010 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setSettingsOpen(false)}
          >
            <SettingsWrapper
              initial={{ x: "-50%", y: "50%", opacity: 0 }}
              animate={{ x: "-50%", y: "-50%", opacity: 1 }}
              exit={{ x: "-50%", y: "50%", opacity: 0 }}
              onClick={(e) => e.stopPropagation()}
              transition={{ duration: 0.4 }}
            >
              <Title>Einstellungen</Title>
              <CookieTitleWrapper>
                <h4>Notwendig</h4>
                <Switch on={true} onChange={() => {}} />
              </CookieTitleWrapper>
              <Text>
                Notwendige Cookies helfen dabei, eine Webseite nutzbar zu
                machen, indem sie Grundfunktionen wie Seitennavigation und
                Zugriff auf sichere Bereiche der Webseite ermöglichen. Die
                Webseite kann ohne diese Cookies nicht richtig funktionieren.
              </Text>
              <CookieTitleWrapper>
                <h4>Statistik</h4>
                <Switch on={useAnalytics} onChange={toggleAnalytics} />
              </CookieTitleWrapper>
              <Text>
                Statistik-Cookies helfen Webseiten-Besitzern zu verstehen, wie
                Besucher mit Webseiten interagieren, indem Informationen anonym
                gesammelt und gemeldet werden.
              </Text>
              <ButtonsWrapper style={{ marginTop: "2rem" }}>
                <Button
                  type={"button"}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  title={"decline cookies"}
                  onClick={() => {
                    declineAnalytics();
                    setSettingsOpen(false);
                    setCookiesOpen(false);
                  }}
                >
                  Ablehnen
                </Button>
                <Button
                  type={"button"}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  title={"accept cookie settings"}
                  onClick={() => {
                    setCurrentCookieSettings();
                    setSettingsOpen(false);
                    setCookiesOpen(false);
                  }}
                >
                  Auswahl bestätigen
                </Button>
                <Button
                  type={"button"}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  title={"accept all cookies"}
                  onClick={() => {
                    allowAnalytics();
                    setSettingsOpen(false);
                    setCookiesOpen(false);
                  }}
                  filled
                >
                  Alle zulassen
                </Button>
              </ButtonsWrapper>
            </SettingsWrapper>
          </Backdrop>
        )}
      </AnimatePresence>
    </Portal>
  );
};

export default Cookies;
