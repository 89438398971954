import styled from "@emotion/styled";
import { motion, useTransform } from "framer-motion";
import { FC } from "react";
import { useApp } from "../hooks/useAppProvider";
import { useMobile } from "../hooks/useMobile";
import useShowContent from "../hooks/useShowContent";

const HeroStyled = styled(motion.div)({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  backgroundColor: "#fff",
  zIndex: 10,
  willChange: "transform",
  height: "50vh",
});

const H3 = styled(motion.h3)({
  fontWeight: 300,
  fontSize: 80,
  textAlign: "center",
  margin: 0,
  top: 150,
  left: "50%",
  position: "fixed",
  zIndex: 10,
  willChange: "transform",
  whiteSpace: "nowrap",
  "&::after": {
    content: "''",
    width: "100%",
    height: 0,
    position: "absolute",
    bottom: 4,
    left: 0,
    borderStyle: "solid",
    borderWidth: 0,
    borderTopWidth: 1,
    borderColor: "rgba(0,0,0,0.5)",
  },
});

const H1 = styled(motion.h1)({
  fontWeight: 100,
  fontSize: 80,
  textAlign: "center",
  margin: 0,
  position: "fixed",
  top: 230,
  willChange: "transform",
  zIndex: 10,
  left: "50%",
});

/*
const P = styled(motion.p)(() => ({
  fontWeight: 300,
  textAlign: "center",
  marginTop: 80,
  position: "fixed",
  willChange: "transform",
  top: "30vh",
  left: "50%",
  zIndex: 10,
}));
*/

const Hero: FC = () => {
  const mobile = useMobile();
  const { scrollY } = useApp();
  const show = useShowContent(0, 3000);
  const y = useTransform(scrollY, [500, 1300], ["0vh", "-50vh"]);
  const yH3 = useTransform(scrollY, [600, 1300], ["0vh", "-60vh"]);
  const yH1 = useTransform(scrollY, [600, 1300], ["0vh", "-70vh"]);
  // const yP = useTransform(scrollY, [500, 1300], ["0vh", "-50vh"]);

  if (!show) {
    return null;
  }

  if (!mobile) {
    return (
      <>
        <HeroStyled style={{ y }} />
        <H3 style={{ y: yH3, x: "-50%" }}>MARTIN FÖRSTER</H3>
        <H1 style={{ y: yH1, x: "-50%" }}>FOTOGRAFIE</H1>
        {/*
        <P style={{ y: yP, x: "-50%" }}>
          HIER STEHT IRGEND EIN
          <br />
          RICHTIG KRASSER SPRUCH DER RICHTIG REIN HAUT
          <br />
          UND ALLE SIND BEGEISTERT
        </P>
        */}
      </>
    );
  }

  return (
    <div
      style={{
        height: "50vh",
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "2rem",
        flexShrink: 0,
      }}
    >
      <h3 style={{ textAlign: "center", margin: 0 }}>MARTIN FÖRSTER</h3>
      <h1 style={{ textAlign: "center", margin: 0 }}>FOTOGRAFIE</h1>
    </div>
  );
};

export default Hero;
