import { FC, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { getAnalytics, logEvent } from "@firebase/analytics";
import { getFunctions, httpsCallable } from "@firebase/functions";
import { AnimatePresence, motion } from "framer-motion";
import ReCAPTCHA from "react-google-recaptcha";
import { useApp } from "../hooks/useAppProvider";
import { useMobile } from "../hooks/useMobile";

const defaultName = process.env.NODE_ENV === "development" ? "Max Jeschek" : "";
const defaultEmail =
  process.env.NODE_ENV === "development" ? "max@maximilianjeschek.de" : "";
const defaultMessage =
  process.env.NODE_ENV === "development" ? "Test Nachricht" : "";

const Backdrop = styled(motion.div)({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100vh",
  backgroundColor: "rgba(0,0,0,0.3)",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1000,
});

const Modal = styled(motion.div, {
  shouldForwardProp: (prop) => prop !== "mobile",
})<{ mobile?: boolean }>(({ mobile }) => ({
  backgroundColor: "#fff",
  borderRadius: 30,
  padding: "3rem",
  maxWidth: "100%",
  overflow: "hidden",
  width: mobile ? "90%" : undefined,
  height: mobile ? "80%" : undefined,
  marginTop: mobile ? "-15%" : undefined,
}));

const Row = styled("div")({
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  gap: "2rem",
  marginBottom: 10,
});

const Label = styled("label")(() => ({
  display: "block",
  width: "100%",
  "& span": {
    display: "block",
    fontSize: 12,
    color: "rgba(0,0,0,0.5)",
    marginBottom: 2,
  },
}));

const Input = styled("input")(() => ({
  borderRadius: 3,
  padding: 8,
  outline: "none",
  display: "block",
  width: "100%",
  borderWidth: 1,
  borderColor: "rgba(0,0,0,0.3)",
}));

const Textarea = styled("textarea")(() => ({
  borderRadius: 3,
  padding: 8,
  outline: "none",
  display: "block",
  width: "100%",
  fontFamily: "inherit",
  borderWidth: 1,
  borderColor: "rgba(0,0,0,0.3)",
}));

const Button = styled(motion.button)({
  outline: "none",
  border: "none",
  backgroundColor: "#000",
  color: "#fff",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  marginTop: 20,
  padding: 20,
  borderRadius: 12,
  fontWeight: "bold",
  cursor: "pointer",
});

const KontaktModal: FC = () => {
  const mobile = useMobile();
  const app = useApp();
  const [name, setName] = useState<string>(defaultName);
  const [email, setEmail] = useState<string>(defaultEmail);
  const [message, setMessage] = useState<string>(defaultMessage);
  const captchaRef = useRef<ReCAPTCHA>(null);
  const [privacyAccepted, setPrivacyAccepted] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const submit = () => {
    const functions = getFunctions();
    if (!functions) {
      setErrorMessage("Systemfehler.");
      setShowError(true);
      return;
    }

    if (!captchaRef.current) {
      setErrorMessage("Systemfehler.");
      setShowError(true);
      return;
    }

    if (!privacyAccepted) {
      setErrorMessage(
        "Sie müssen die AGB und Datenschutzbestimmungen akzeptieren."
      );
      setShowError(true);
      return;
    }

    const captchaToken = captchaRef.current.getValue();

    if (!captchaToken) {
      setErrorMessage("Bitte bestätige, das du kein Roboter bist.");
      setShowError(true);
      return;
    }

    const sendMail = httpsCallable(functions, "sendMail");

    sendMail({ privacyAccepted, name, email, message, captchaToken })
      .then(() => {
        setShowSuccess(true);
        const analytics = getAnalytics();
        if (analytics) {
          logEvent(analytics, "kontaktformular_submit", {
            success: true,
          });
        }
        if (captchaRef.current) {
          captchaRef.current.reset();
        }
      })
      .catch(() => {
        setShowError(true);
        setErrorMessage("Es ist ein Fehler aufgetreten.");
        const analytics = getAnalytics();
        if (analytics) {
          logEvent(analytics, "kontaktformular_submit", {
            success: false,
          });
        }
      });
  };

  useEffect(() => {
    if (app.showKontaktModal) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [app.showKontaktModal]);

  return (
    <>
      <AnimatePresence>
        {app.showKontaktModal && (
          <Backdrop
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => app.setShowKontaktModal(false)}
          >
            <Modal
              initial={{ y: -100, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -100, opacity: 0 }}
              onClick={(e) => e.stopPropagation()}
              mobile={mobile}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  paddingBottom: 10,
                }}
              >
                <button
                  type={"button"}
                  onClick={() => app.setShowKontaktModal(false)}
                  style={{
                    border: "none",
                    outline: "none",
                    background: "transparent",
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <svg
                    fill={"none"}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    width={30}
                    height={30}
                  >
                    <title>Close</title>
                    <path
                      fill="none"
                      color={"#000"}
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={40}
                      d="M368 368L144 144M368 144L144 368"
                    />
                  </svg>
                </button>
              </div>
              <h1 style={{ margin: "0 0 2rem 0" }}>
                Ich würde mich freuen von dir zu hören.
                <br />
                Nehme Kontakt auf!
              </h1>
              <Row>
                <Label>
                  <span>Dein Name</span>
                  <Input
                    type={"text"}
                    value={name}
                    onChange={(e) => setName(e.currentTarget.value)}
                    placeholder={"Name angeben"}
                  />
                </Label>
                <Label>
                  <span>Deine E-Mail</span>
                  <Input
                    type={"email"}
                    value={email}
                    onChange={(e) => setEmail(e.currentTarget.value)}
                    placeholder={"max@mustermann.de"}
                  />
                </Label>
              </Row>
              <Label>
                <span>Deine Nachricht</span>
                <Textarea
                  value={message}
                  onChange={(e) => setMessage(e.currentTarget.value)}
                  placeholder={"Gib eine Nachricht ein"}
                />
              </Label>
              <Label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                <Input
                  style={{ width: "auto" }}
                  type={"checkbox"}
                  value={privacyAccepted ? "checked" : ""}
                  onChange={(e) => setPrivacyAccepted(e.currentTarget.checked)}
                />
                <span style={{ display: "flex" }}>
                  Hiermit akzeptiere ich die AGB und Datenschutzbestimmungen
                </span>
              </Label>
              <ReCAPTCHA
                ref={captchaRef}
                sitekey={"6LfD0aQkAAAAAGHbf-3o8pmyQX6rWaFfTNEXObxW"}
                size={"normal"}
              />
              {showError && <p style={{ color: "red" }}>{errorMessage}</p>}
              <Button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                type={"button"}
                onClick={submit}
              >
                Nachricht senden!
              </Button>
            </Modal>
          </Backdrop>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showSuccess && (
          <Backdrop>
            <Modal style={{ padding: "2rem" }}>
              <h3 style={{ margin: 0 }}>
                Beep Boop. Deine Nachricht ist erfolgreich eingegangen.
              </h3>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  type={"button"}
                  onClick={() => {
                    setShowSuccess(false);
                    app.setShowKontaktModal(false);
                  }}
                >
                  Super!
                </Button>
              </div>
            </Modal>
          </Backdrop>
        )}
      </AnimatePresence>
    </>
  );
};

export default KontaktModal;
