import styled from "@emotion/styled";
import { motion, useScroll, useTransform } from "framer-motion";
import stepOne from "../images/Irland-31.jpg";
import { FC } from "react";
import useShowContent from "../hooks/useShowContent";
import { useApp } from "../hooks/useAppProvider";
import { useMobile } from "../hooks/useMobile";

const StepOneStyled = styled(motion.div, {
  shouldForwardProp: (prop) => prop !== "mobile",
})<{ mobile: boolean }>(({ mobile }) => ({
  backgroundImage: `url('${stepOne}')`,
  backgroundSize: "cover",
  backgroundPosition: mobile ? "top" : undefined,
  backgroundRepeat: "no-repeat",
  position: mobile ? "relative" : "absolute",
  width: "100%",
  height: mobile ? "80vh" : "120vh",
  minHeight: mobile ? "80vh" : "unset",
  maxHeight: mobile ? "80vh" : "unset",
  inset: mobile ? "unset" : 0,
  zIndex: mobile ? 0 : 1,
  willChange: "transform",
}));

const Start: FC = () => {
  const { scrollY: mobileScrollY } = useScroll();
  const mobile = useMobile();
  const { scrollY } = useApp();
  const show = useShowContent(0, 3000);
  const scale = useTransform(scrollY, [1000, 1500], [1.5, 1]);
  const x = useTransform(scrollY, [1500, 2500], ["0%", "-100%"]);
  const y = useTransform(scrollY, [0, 1000], ["0vh", "-20vh"]);
  const yMobile = useTransform(mobileScrollY, [0, 1000], ["0vh", "-20vh"]);

  if (!show) {
    return null;
  }

  return (
    <>
      <StepOneStyled
        mobile={mobile}
        style={{ scale: mobile ? 1 : scale, x, y: mobile ? yMobile : y }}
        transition={{
          type: "keyframes",
          ease: "easeInOut",
        }}
      />
    </>
  );
};

export default Start;
