import React, { FC, useState } from "react";
import styled from "@emotion/styled";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AnimatePresence,
  motion,
  useMotionValue,
  useTransform,
} from "framer-motion";
import { stamps, Stamp as StampType, useApp } from "../hooks/useAppProvider";
import { useMobile } from "../hooks/useMobile";
import Sidebar from "./Sidebar";

const HeaderStyled = styled("header")({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  width: "100%",
  zIndex: 10,
  userSelect: "none",
  height: 60,
  maxHeight: 60,
  minHeight: 60,
});

const HeaderBackground = styled("div")({
  backgroundColor: "rgba(0,0,0,0.5)",
  backdropFilter: "blur(18px)",
  width: "100%",
  height: 60,
  position: "absolute",
  top: 0,
  left: 0,
});

const Body = styled("div")({
  color: "#fff",
  paddingLeft: 15,
  paddingRight: 15,
  height: "100%",
  position: "relative",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  userSelect: "none",
});

const Links = styled("nav")({
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  gap: "1rem",
  userSelect: "none",
});

const HomeButton = styled("button")({
  border: "none",
  backgroundColor: "transparent",
  color: "#fff",
  fontSize: 15,
  textDecoration: "none",
  userSelect: "none",
  "&:hover": {
    textDecoration: "underline",
  },
  cursor: "pointer",
});

const ScrollIndicator = styled(motion.div)({
  position: "absolute",
  top: "100%",
  left: 0,
  width: "100%",
  cursor: "pointer",
  backgroundColor: "transparent",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-start",
});

const Indicator = styled(motion.div)(() => ({
  transformOrigin: "0%",
  backgroundColor: "#ff3030",
  height: "100%",
  width: "100%",
}));

const Ball = styled(motion.div)({
  width: 27,
  height: 27,
  borderRadius: "50%",
  backgroundColor: "rgba(255,255,255,1)",
  position: "absolute",
  top: 0,
  left: 0,
  boxShadow: "0 0 10px rgba(0,0,0,0.2)",
});

const Stamp: React.FC<StampType> = (props) => {
  const app = useApp();

  const percentage = props.end / app.scrollMaxY;

  return (
    <motion.div
      style={{
        width: 2,
        backgroundColor: "#fff",
        height: "100%",
        position: "absolute",
        top: 0,
        left: percentage * 100 + "%",
      }}
    />
  );
};

const MobileMenuButton = styled(motion.button)({
  margin: 0,
  border: "none",
  outline: "none",
  background: "transparent",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
});

const HeaderButton = styled("button")({
  background: "transparent",
  border: "none",
  outline: "none",
  color: "#fff",
  cursor: "pointer",
  margin: 0,
  padding: 0,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  fontSize: 14,
  "&:hover": {
    textDecoration: "underline",
  },
});

const Header: FC = () => {
  const location = useLocation();
  const mobile = useMobile();
  const navigate = useNavigate();
  const app = useApp();
  const scaleX = useTransform(app.scrollY, [0, app.scrollMaxY], ["0%", "100%"]);
  const [showIndicator, setShowIndicator] = useState(false);
  const [mouseDown, setMouseDown] = useState(false);
  const ballX = useMotionValue(0);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

  const handleMainClick = () => {
    if (window.location.pathname === "/") {
      if (!mobile) {
        app.scrollToStart();
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    } else {
      navigate("/");
    }
  };

  const handleIndicatorClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const x = e.clientX;
    const percentage = x / window.innerWidth;
    const result = app.scrollMaxY * percentage;
    app.scrollToPosition(result);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    const x = e.clientX - 14;
    ballX.set(x);

    if (mouseDown) {
      const percentage = x / window.innerWidth;
      const result = app.scrollMaxY * percentage;
      app.scrollToPosition(result);
    }
  };

  const handleMouseLeave = () => {
    setShowIndicator(false);
    setMouseDown(false);
  };

  const handleMouseEnter = () => {
    setShowIndicator(true);
  };

  return (
    <HeaderStyled>
      <HeaderBackground />
      <Body>
        <HomeButton type={"button"} onClick={handleMainClick}>
          Martin Förster Fotografie
        </HomeButton>
        {location.pathname === "/" && (
          <motion.p
            key={app.currentStory}
            initial={{ y: "-100%" }}
            animate={{ y: "-50%" }}
            exit={{ y: "-100%" }}
            style={{
              margin: 0,
              position: "absolute",
              top: "50%",
              left: "50%",
              x: "-50%",
            }}
          >
            {app.currentStory}
          </motion.p>
        )}
        {!mobile && (
          <Links>
            <HeaderButton
              type={"button"}
              onClick={() => app.setCookiesOpen(true)}
            >
              Cookie-Einstellungen
            </HeaderButton>
            <HeaderButton
              type={"button"}
              onClick={() => navigate("/impressum")}
            >
              Impressum
            </HeaderButton>
            <HeaderButton
              type={"button"}
              onClick={() => navigate("/datenschutz")}
            >
              Datenschutz
            </HeaderButton>
          </Links>
        )}
        {mobile && location.pathname === "/" && (
          <MobileMenuButton
            type={"button"}
            onClick={() => setSidebarOpen(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              fill={"#fff"}
              viewBox="0 0 512 512"
            >
              <title>Menu</title>
              <path
                fill={"#fff"}
                color={"#fff"}
                stroke="currentColor"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={32}
                d="M80 160h352M80 256h352M80 352h352"
              />
            </svg>
          </MobileMenuButton>
        )}
      </Body>
      {!mobile && location.pathname === "/" && (
        <ScrollIndicator
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onMouseMove={handleMouseMove}
          onMouseDown={() => setMouseDown(true)}
          onMouseUp={() => setMouseDown(false)}
          onClick={handleIndicatorClick}
          initial={{ height: 5, y: -5 }}
          whileHover={{
            height: 10,
            y: -10,
            backgroundColor: "rgba(255,255,255,0.2)",
          }}
        >
          <Indicator style={{ scaleX }} />
          <AnimatePresence>
            {showIndicator && (
              <Ball
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.1 }}
                style={{ x: ballX, y: -9 }}
              />
            )}
          </AnimatePresence>
          {stamps.map((stamp, index) => (
            <Stamp key={index} {...stamp} />
          ))}
        </ScrollIndicator>
      )}
      {mobile && (
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      )}
    </HeaderStyled>
  );
};

export default Header;
