import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import PageLoading from "../components/PageLoading";

const MobileContext = createContext<boolean>(window.innerWidth < 768);
const useMobile = () => useContext(MobileContext);
const useMobileProvider = () => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener("load", handleResize);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("load", handleResize);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobile;
};
const MobileProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const value = useMobileProvider();
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => setIsReady(true), 500);
  }, []);

  if (!isReady) {
    return <PageLoading />;
  }

  return (
    <MobileContext.Provider value={value}>{children}</MobileContext.Provider>
  );
};

export { useMobile, MobileProvider };
