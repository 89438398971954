import { useEffect, useState } from "react";
import { useApp } from "./useAppProvider";
import { useMobile } from "./useMobile";

const useShowContent = (min: number, max: number) => {
  const mobile = useMobile();
  const { scrollY } = useApp();
  const [show, setShow] = useState(false);

  useEffect(() => {
    let cancel = false;

    if (scrollY.get() === 0 && min === 0) {
      if (!cancel) {
        setShow(true);
      }
    } else if (scrollY.get() <= min) {
      if (!cancel) {
        setShow(false);
      }
    } else if (scrollY.get() <= max) {
      if (!cancel) {
        setShow(true);
      }
    } else {
      if (!cancel) {
        setShow(false);
      }
    }

    scrollY.on("change", (v) => {
      if (v === 0 && min === 0) {
        if (!cancel) {
          setShow(true);
        }
      } else if (v <= min) {
        if (!cancel) {
          setShow(false);
        }
      } else if (v <= max) {
        if (!cancel) {
          setShow(true);
        }
      } else {
        if (!cancel) {
          setShow(false);
        }
      }
    });

    return () => {
      cancel = true;
    };
  }, [max, min, scrollY]);

  if (mobile) {
    return true;
  }

  return show;
};

export default useShowContent;
