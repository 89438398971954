import styled from "@emotion/styled";
import { AnimatePresence, motion } from "framer-motion";
import { FC } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Portal from "./Portal";
import { useApp } from "../hooks/useAppProvider";

const SidebarClose = styled(motion.div)({
  position: "fixed",
  top: 0,
  left: 0,
  width: "20%",
  height: "100vh",
  backgroundColor: "rgba(0,0,0,0.1)",
});

const sidebarTransition = {
  duration: 0.3,
  type: "spring",
  damping: 20,
  stiffness: 100,
};

const SidebarStyled = styled(motion.div)({
  position: "fixed",
  top: 0,
  left: "20%",
  height: "100vh",
  backgroundColor: "#000",
  width: "80vw",
  color: "#fff",
  zIndex: 200,
  display: "flex",
  flexDirection: "column",
});

const SidebarList = styled("nav")({
  margin: 0,
  padding: "0 0 2rem 0",
  listStyle: "none",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  "& div": {
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    paddingTop: 8,
    paddingBottom: 8,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    fontSize: 20,
    "& a": {
      color: "#fff",
      textDecoration: "none",
      marginLeft: "auto",
    },
  },
});

type Props = {
  setSidebarOpen: (value: boolean) => void;
  sidebarOpen: boolean;
};

const Sidebar: FC<Props> = ({ setSidebarOpen, sidebarOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const app = useApp();

  const handleScroll = (target: string) => {
    setSidebarOpen(false);
    if (location.pathname !== "/") {
      navigate("/#" + target);
      return;
    }

    const el = document.getElementById(target) as HTMLElement;
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Portal wrapperId={"sidebar"}>
      {sidebarOpen && (
        <SidebarClose
          initial={{ x: "-100%", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: "-100%", opacity: 0 }}
          onClick={() => setSidebarOpen(false)}
          transition={sidebarTransition}
        />
      )}
      <AnimatePresence>
        {sidebarOpen && (
          <SidebarStyled
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={sidebarTransition}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <button
                type={"button"}
                onClick={() => setSidebarOpen(false)}
                style={{
                  background: "none",
                  border: "none",
                  outline: "none",
                  padding: "1rem",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <svg
                  fill={"#fff"}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  width={24}
                  height={24}
                >
                  <title>Close</title>
                  <path
                    fill="#fff"
                    color={"#fff"}
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={32}
                    d="M368 368L144 144M368 144L144 368"
                  />
                </svg>
              </button>
            </div>
            <SidebarList>
              <div onClick={() => handleScroll("campaign")}>CAMPAIGN</div>
              <div onClick={() => handleScroll("portrait")}>PORTRAIT</div>
              <div onClick={() => handleScroll("reportage")}>REPORTRAGE</div>
              <div onClick={() => handleScroll("press")}>PRESS</div>
              <div onClick={() => handleScroll("sport")}>SPORT</div>
              <div onClick={() => handleScroll("classical")}>CLASSICAL</div>
              <div style={{ marginTop: "auto" }}>
                <Link onClick={() => setSidebarOpen(false)} to={"/impressum"}>
                  Impressum
                </Link>
              </div>
              <div>
                <Link onClick={() => setSidebarOpen(false)} to={"/datenschutz"}>
                  Datenschutz
                </Link>
              </div>
              <div
                onClick={() => {
                  app.setCookiesOpen(true);
                  setSidebarOpen(false);
                }}
                style={{ justifyContent: "flex-end" }}
              >
                Cookie Einstellungen
              </div>
            </SidebarList>
          </SidebarStyled>
        )}
      </AnimatePresence>
    </Portal>
  );
};

export default Sidebar;
