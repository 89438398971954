import styled from "@emotion/styled";
import { FC } from "react";
import { Link as NavLink, useLocation, useNavigate } from "react-router-dom";
import { useApp } from "../hooks/useAppProvider";

const Section = styled("section")(() => ({
  width: "100%",
  backgroundColor: "#000",
  color: "#fff",
  overflow: "hidden",
}));

const Links = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  marginTop: 20,
});

const Link = styled(NavLink)(() => ({
  fontSize: 12,
  color: "#fff",
}));

const Title = styled("h4")({ margin: 0, textAlign: "left" });

const Subtitle = styled("p")({
  margin: 0,
  textAlign: "right",
  fontSize: 11,
  marginTop: 15,
  "& a": {
    textDecoration: "underline",
    color: "#fff",
    fontSize: "inherit",
  },
});

const FooterButton = styled("button")({
  fontSize: 12,
  color: "#fff",
  textDecoration: "underline",
  background: "transparent",
  outline: "none",
  border: "none",
  cursor: "pointer",
  textAlign: "left",
  padding: 0,
  margin: 0,
});

const MobileFooter: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { setCookiesOpen } = useApp();

  return (
    <Section id={"footer"}>
      <div style={{ padding: "2rem 1rem" }}>
        <Title>Martin Förster Fotografie</Title>
        <Links>
          <Link to={"/"}>Startseite</Link>
          <Link to={"/impressum"}>Impressum</Link>
          <Link to={"/datenschutz"}>Datenschutz</Link>
          <FooterButton
            type={"button"}
            onClick={() => {
              setCookiesOpen(true);
              if (pathname !== "/") {
                navigate("/");
              }
            }}
          >
            Cookie-Einstellungen
          </FooterButton>
        </Links>
        <Subtitle>
          Designed by{" "}
          <a href={"https://maximilianjeschek.de"} rel={"noreferrer"}>
            @maxjeschek
          </a>
        </Subtitle>
      </div>
    </Section>
  );
};

export default MobileFooter;
