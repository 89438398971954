import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Header from "./components/Header";
import KontaktModal from "./components/KontaktModal";
import Cookies from "./components/Cookies";
import Impressum from "./pages/Impressum";
import Datenschutz from "./pages/Datenschutz";
import NotFound from "./pages/NotFound";
import { useMobile } from "./hooks/useMobile";
import MobileFooter from "./components/MobileFooter";
import DesignedBy from "./components/DesignedBy";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  const mobile = useMobile();

  return (
    <>
      <KontaktModal />
      <Cookies />
      <Header />
      <Routes>
        <Route path={"/"} element={<Home />} />
        <Route path={"/impressum"} element={<Impressum />} />
        <Route path={"/datenschutz"} element={<Datenschutz />} />
        <Route path={"*"} element={<NotFound />} />
      </Routes>
      {mobile && <MobileFooter />}
      <DesignedBy />
      <ScrollToTop />
    </>
  );
}

export default App;
