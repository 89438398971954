import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { MobileProvider } from "./hooks/useMobile";
import { AppProvider } from "./hooks/useAppProvider";
import { initializeApp } from "firebase/app";
import { initializeAnalytics } from "@firebase/analytics";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const firebaseConfig = {
  apiKey: "AIzaSyCeFUQLUg3HNNhsWkt4QBthqs52_HQLAHw",
  authDomain: "foerstermartin-96bf6.firebaseapp.com",
  projectId: "foerstermartin-96bf6",
  storageBucket: "foerstermartin-96bf6.appspot.com",
  messagingSenderId: "196306494996",
  appId: "1:196306494996:web:d45e0de4e11f5aa2f22901",
  measurementId: "G-CC23ECFR2Z",
};

const app = initializeApp(firebaseConfig, { automaticDataCollectionEnabled: false })
initializeAnalytics(app);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <MobileProvider>
        <AppProvider>
          <App />
        </AppProvider>
      </MobileProvider>
    </BrowserRouter>
  </React.StrictMode>
);
