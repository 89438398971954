import {FC} from "react";

const Impressum: FC = () => {
    return <div style={{minHeight: "100vh", paddingTop: 90, paddingLeft: 10, paddingRight: 10}}>
        <h1>Impressum</h1>

        <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
        <p>Martin F&ouml;rster<br/>
            Martin F&ouml;rster Fotografie<br/>
            Wettinstrasse 5<br/>
            01445 Radebeul</p>

        <h2>Kontakt</h2>
        <p>Telefon: <a href="tel:+491733802800">+491733802800</a><br/>
            E-Mail: <a href="mailto:foto.martin@t-online.de">foto.martin@t-online.de</a></p>

        <h2>Umsatzsteuer-ID</h2>
        <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br/>
            DE276399294</p>

        <h2>Berufsbezeichnung und berufsrechtliche Regelungen</h2>
        <p>Berufsbezeichnung:<br/>
            Fotograf</p>
        <p>Zust&auml;ndige Kammer:<br/>
        </p>
        <p>Verliehen in:<br/>
        </p>
        <p>Es gelten folgende berufsrechtliche Regelungen:</p>
        <h2>Redaktionell verantwortlich</h2>
        <p>Martin F&ouml;rster</p>

        <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
        <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.</p>
    </div>;
};

export default Impressum;
